import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogueService } from 'src/app/services/catalogue.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-catalogue-detail',
  templateUrl: './catalogue-detail.component.html',
  styleUrls: ['./catalogue-detail.component.scss']
})
export class CatalogueDetailComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  private sub: any;
  public catalogue: any;
  public canEdit: boolean;

  /**
   * Constructor
   */
  constructor(
    private route: ActivatedRoute,
    private catalogueService: CatalogueService,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    this.catalogue = {
      data: []
    };
  }

  /**
   * OnInit
   */
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.loadCatalogue(params.id);
    });
    this.canEdit = this.authService.isAdmin();
  }

  /**
   * OnDestroy
   */
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadCatalogue(id: string) {
    this.catalogueService.getCatalogueById(id).then((result) => {
      this.catalogue = { ...this.catalogue, ...result };
    });
  }

  newLine() {
    this.catalogue.data.push({
      description: '',
      tasks: '',
      expectation: ''
    });
  }

  saveCatalogue() {
    if (this.catalogue.title) {
      if (this.catalogue.id) {
        this.catalogueService.updateCatalogue(this.catalogue.id, this.catalogue).then((result) => {
          this.catalogue = { ...this.catalogue, ...result };
        });
      } else {
        this.catalogueService.createCatalogue(this.catalogue).then((result) => {
          this.catalogue = { ...this.catalogue, ...result };
        });
      }
      this.notify();
      return true;
    }
    this.notify();
    return false;
  }

  saveCatalogueAndClose() {
    if (this.saveCatalogue()) {
      this.router.navigate(['/catalogues']);
    }
  }

  saveResult() {
    this.catalogue.originId = this.catalogue.id;
    this.catalogue.tester   = this.authService.getUser();
    this.catalogueService.createResult(this.catalogue).then((result) => {
      this.router.navigate(['/catalogues']);
      this.notify();
    });
  }

  notify() {
    this.notificationService.changeMessage('BLABLABLA');

    setTimeout(() => {
      this.notificationService.changeMessage(null);
    }, 1000);
  }

  checkRow(index: number) {
    this.catalogue.data[index].failed  = false;
    this.catalogue.data[index].checked = true;
  }

  failRow(index: number) {
    this.catalogue.data[index].checked = false;
    this.catalogue.data[index].failed  = true;
  }

  deleteRow(index: number) {
    this.catalogue.data.splice(index, 1);
  }
}
